body {
  margin: 0;
  scroll-behavior: smooth;
  font-family: Arial Rounded MT Bold;
  -webkit-font-smoothing: antialiased;
  overflow-x: auto;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Arial Rounded MT Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Rounded MT Bold"), url("ARLRDBD_0.woff") format("woff");
}

.dropdownCardWhite {
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  padding: 1rem;
}

.dropdownCard:hover {
  border: 1px solid black;
}

.dropdownCardWhite:hover {
  border-bottom: 1px solid white;
}

.center-col {
  position: relative;
  height: 100vh;
  background: #bc1e3e;
}

.right-col {
  position: relative;
  width: 100%;
  padding-top: 4rem;
}

.card-scroll {
  position: relative;
}

.card-body-scroll {
  position: relative;
  height: 375px;
  overflow-y: scroll;
}

.sidebarItem {
  cursor: pointer;
}

.sidebarItem:hover {
  background: "#991833";
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.contained {
  position: relative;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 23px;
}

.collapsed {
  display: none;
}